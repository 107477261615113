<template>
  <nav class="navbar navbar-expand-md navbar-light navbar-laravel">
    <div class="container">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="navbar-nav ml-auto">
          <template v-if="user.loggedIn">
            <li class="nav-item">
              <div class="nav-item">Hello! {{ user.data.email }}</div>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="signOut">Abmelden</a>
            </li>
            <!--
            <li class="nav-item">
              <div class="nav-item">{{ user.data.key }}</div>
              {{ user.loggedIn }}
            </li>
            -->
          </template>
          <template v-else>
            <!--
            <li class="nav-item">
              <router-link to="login" class="nav-link">Sign In</router-link>
            </li>
            <li class="nav-item">
              <router-link to="register" class="nav-link">Register</router-link>
            </li>
            -->
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import axios from 'axios'

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user"
    })
  },
  methods: {
    signOut() {
      alert('Good by')
      localStorage.removeItem("crown");
      localStorage.removeItem("crownBayerId");
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getAnonUser')
        .then((result) => {
          this.crownKey = result.data
        })
        .finally(() => {
          localStorage.setItem('crown', this.crownKey);
        });
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push('/').catch(() => { });
        });
    }
  }
};
</script>

<style scoped>
li.nav-item {
  list-style-type: none;
}
</style>