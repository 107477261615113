import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../components/Login.vue'
import Register from '../components/Register'
import Dashboard from '../components/Dashboard'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'House',
    component: () => import( /* webpackChunkName: "house" */ '../views/House.vue'),
    props: true
  },
  {
    path: '/flats',
    name: 'Flats',
    component: () => import( /* webpackChunkName: "flats" */ '../views/Flats.vue'),
    props: true
  },
  {
    path: '/:user/flat/:flat/:flatID/:number/:action/:styleStart/:cost/:framedMeters',
    name: 'Flat',
    component: () => import( /* webpackChunkName: "rooms" */ '../views/Rooms.vue'),
    props: true
  },
  {
    path: '/rundgang',
    name: 'Rundgang',
    component: () => import( /* webpackChunkName: "rooms" */ '../views/Rundgang.vue'),
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import( /* webpackChunkName: "rooms" */ '../views/Kontakt.vue'),
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import( /* webpackChunkName: "rooms" */ '../views/Impressum.vue'),
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import( /* webpackChunkName: "rooms" */ '../views/Datenschutz.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  }
]

const router = new VueRouter({
  routes
})

export default router
