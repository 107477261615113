<template>
  <div class="container">
    <div id="bg">
      <img :src="img" alt="" />
    </div>
    <Menu />
    <div class="container__form">
      <div class="crown__house--block-header">
        <div class="crown__house--side">
          <div class="crown__house--side-home">
            <div class="crown__house--side-homename">strandkai</div>
            <div class="crown__house--side-homeside">&nbsp;</div>
            <div class="crown__house--side-homepage">
              <router-link class="menu__logo" to="/">
                Käufer-Login &#9660;
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="outerContainerLogin" v-if="getPassword == false">
        <div class="formContainer">
          <div v-if="visibleFlats">
            <div class="formHeadline form__headline">
              <p class="headline">Wohnungsauswahl</p>
            </div>

            <div class="formFlats">
              <div v-for="(flat, indexFlat) in flatsUser" :key="indexFlat">
                <button
                  class="flats__button"
                  v-on:click="goToFlat(flat.flatID)"
                >
                  {{ flat.number }}
                </button>
              </div>
            </div>
          </div>

          <div class="formHeadline" v-if="!visibleFlats">
            <p class="headline">Käufer-Login</p>
          </div>

          <form
            v-if="!visibleFlats"
            id="loginForm"
            class="form"
            action="#"
            @submit.prevent="submit(form.email)"
          >
            <input type="hidden" name="loginRegistrationSiteId" value="0" />
            <div class="formInput">
              <input
                id="email"
                type="email"
                name="email"
                value
                required
                autofocus
                v-model="form.email"
                placeholder="E-mail (Pflichtfeld)"
              />
              <!--
            <input
              id="loginEmail"
              type="email"
              placeholder="E-mail (Pflichtfeld)"
              value=""
              name="loginEmail"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
            />
            --></div>
            <div class="formInput disabledInput">
              <input
                id="password"
                class="password icon-eye"
                placeholder="Passwort"
                type="password"
                name="password"
                v-model="form.password"
                autocomplete="on"
              />
              <!--
            <input
              id="loginPassword"
              class="password icon-eye"
              type="password"
              placeholder="Passwort (Noch nicht aktiv)"
              value=""
              name="loginPassword"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              disabled
            />
            -->
              <span class="loginPasswordView showPassword icon-eye"></span>
            </div>
            <div class="formInput simpleButton">
              <p class="pointer" v-on:click="getPassword = true">
                Passwort vergessen?
              </p>
            </div>
            <div class="formInput formButton">
              <div v-if="error">{{ error }}</div>
            </div>

            <div class="formNotice">
              <input id="terms" type="checkbox" v-model="terms" />
              <label for="terms">
                <p>
                  Ja, ich habe die
                  <router-link :to="{ name: 'Datenschutz' }" class="link-daten"
                    >"allgemeinen Geschäftsbedingungen"</router-link
                  >
                  gelesen und akzeptiere diese
                </p>
              </label>
            </div>
            <div class="formInput formButton">
              <button type="submit" :disabled="isDisabled">Login</button>
            </div>
          </form>
        </div>
      </div>

      <div class="outerContainerLogin" v-if="getPassword == true">
        <div class="formContainer">
          <div class="formHeadline">
            <p class="headline">Bitte geben Sie ihre E-Mail-Adresse ein</p>
          </div>
          <form
            id="loginForm"
            class="form"
            action="#"
            @submit.prevent="getPasswordEmail(form.email)"
          >
            <input type="hidden" name="loginRegistrationSiteId" value="0" />
            <div class="formInput">
              <input
                id="email"
                type="email"
                name="email"
                value
                required
                autofocus
                v-model="form.email"
                placeholder="E-mail (Pflichtfeld)"
              />
            </div>
            <div class="formInput formButton">
              <button type="submit">Senden</button>
            </div>
            <div class="formInput simpleButton">
              <p class="pointer" v-on:click="getPassword = false">Zurück</p>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="crown__house--block">
      <div class="crown__house--side">
        <div class="crown__house--side-home">
          <div class="crown__house--side-homename">&nbsp;</div>
          <div class="crown__house--side-homeside">&nbsp;</div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase"
import Menu from './../components/Menu'
import imgOst from './../assets/kontakt/back-kontakt.jpg'
import axios from 'axios'

export default {
  data() {
    return {
      flatsUser: [],
      visibleFlats: false,
      img: imgOst,
      data: {},
      form: {
        email: "",
        password: ""
      },
      error: null,
      request: '',
      terms: false,
      rooms: {},
      flats: {},
      getPassword: false,
      getEmail: false
    };
  },
  components: {
    Menu
  },
  computed: {
    isDisabled: function () { return !this.terms; }
  },
  methods: {
    goToFlat(flatID) {
      localStorage.setItem('crownBayerFlatId', flatID);
      axios.get('https://cadman-63dc7.firebaseio.com/Flats.json')
        .then((response) => {
          this.flats = response.data
        })
        .finally(() => {
          for (let key in this.flats) {
            if (this.flats[key].Data.FlatID == flatID) {
              axios.get('https://cadman-63dc7.firebaseio.com/Flats/' + key + '/.json')
                .then((response) => {
                  this.rooms = response.data.Data.SubEntitiesIds;
                })
                .finally(() => {
                  let startStyle = 'SILK'
                  axios.get('https://cadman-63dc7.firebaseio.com/Users/' + localStorage.getItem('crown') + '/UnitId/' + localStorage.getItem('crownBayerFlatId') + '/2/.json')
                    .then((response) => {
                      startStyle = response.data.style
                    })
                    .finally(() => {
                      localStorage.setItem('crownRoomStyle', startStyle)
                      this.$router.push({ name: 'Flat', params: { flat: key, number: this.flats[key].Data.displayName, rooms: this.rooms, flatID: localStorage.getItem('crownBayerFlatId'), user: localStorage.getItem('crown'), action: 'design', roomStart: 'Wohnen', styleStart: startStyle, framedMeters: this.flats[key].Data.Square, cost: this.flats[key].Data.UDID } });
                    })
                })
            }
          }
        })
    },

    getPasswordEmail(email) {
      let url = 'https://us-central1-cadman-63dc7.cloudfunctions.net/getUserPassword?email=' + email
      let q = null

      axios.get(url)
        .then((response) => {
          q = response.data
        })
        .finally(() => {
          if (q == false) {
            this.$router.push({ name: 'Kontakt' });
          }
          if (q == 'done') {
            this.getPassword = false
          }
        })
    },

    submit(email) {

      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getAuthData?email=' + email)
        .then((response) => {
          this.request = response.data
        })
        .finally(() => {

          /*

          if (this.request.unitId.length > 1) {
            axios.get('https://cadman-63dc7.firebaseio.com/Flats.json')
              .then((response) => {
                this.flats = response.data
              })
              .finally(() => {
                for (let key in this.flats) {
                  for (let i = 0; i < this.request.unitId.length; i++) {
                    if (this.flats[key].Data.FlatID == this.request.unitId[i]) {

                      axios.get('https://cadman-63dc7.firebaseio.com/Flats/' + this.flats[key] + '/.json')
                        .then((response) => {
                          this.rooms = response.data.Data.SubEntitiesIds;
                        })
                        .finally(() => {
                          this.flatsUser.push({ number: this.flats[key].Data.displayName, flatID: this.request.unitId[i] });
                          this.visibleFlats = true
                        })

                    }
                  }
                }
              })
          }

          */

          if (this.request == false) {
            this.$router.push({ name: 'Kontakt' });
          }

          if (this.request != '') {
            localStorage.setItem('crown', this.request.key);
            localStorage.setItem('crownBayerId', this.request.bayerId);
            localStorage.setItem('crownBayerFlatId', this.request.unitId);
            //this.$store.dispatch("ADD_LOCALSTORAGE_KEY", this.request);
            firebase
              .auth()
              .signInWithEmailAndPassword(this.form.email, this.form.password)
              .then(data => {
                console.log(data)

                if (data.user.displayName == 'Superadmin') {
                  this.$router.push({ name: 'Flats' })
                }

                var flatsUser = []
                for (let key in this.request.unitId) {
                  flatsUser.push(key)
                }

                localStorage.setItem('crownBayerFlatId', flatsUser);

                if (data.user.displayName != 'Superadmin') {
                  if (flatsUser.length > 1) {
                    axios.get('https://cadman-63dc7.firebaseio.com/Flats.json')
                      .then((response) => {
                        this.flats = response.data
                      })
                      .finally(() => {
                        for (let key in this.flats) {
                          for (let i = 0; i < flatsUser.length; i++) {
                            if (this.flats[key].Data.FlatID == flatsUser[i]) {
                              axios.get('https://cadman-63dc7.firebaseio.com/Flats/' + this.flats[key] + '/.json')
                                .then((response) => {
                                  this.rooms = response.data.Data.SubEntitiesIds;
                                })
                                .finally(() => {
                                  this.flatsUser.push({ number: this.flats[key].Data.displayName, flatID: flatsUser[i] });
                                  this.visibleFlats = true
                                })

                            }
                          }
                        }
                      })
                  }
                  else
                    axios.get('https://cadman-63dc7.firebaseio.com/Flats.json')
                      .then((response) => {
                        this.flats = response.data
                      })
                      .finally(() => {
                        for (let key in this.flats) {
                          if (this.flats[key].Data.FlatID == localStorage.getItem('crownBayerFlatId')) {
                            axios.get('https://cadman-63dc7.firebaseio.com/Flats/' + key + '/.json')
                              .then((response) => {
                                this.rooms = response.data.Data.SubEntitiesIds;
                              })
                              .finally(() => {
                                this.$router.push({ name: 'Flat', params: { flat: key, number: this.flats[key].Data.displayName, rooms: this.rooms, flatID: localStorage.getItem('crownBayerFlatId'), user: localStorage.getItem('crown'), action: 'design', roomStart: 'Wohnen', styleStart: 'SILK', framedMeters: this.flats[key].Data.Square, cost: this.flats[key].Data.UDID } });
                              })
                          }
                        }
                      })
                }
              })
              .catch(err => {
                console.log(err.message)
                this.error = 'Wir haben das Passwort an Ihre E-Mail gesendet'
              });
          }
        })
    }
  }
};
</script>

<style scoped>
.crown__house--block button {
  padding: 0;
  margin: 1em;
  border: 0px solid #fff;
  background-color: rgba(255, 255, 255, 0);
  text-shadow: 1px 1px 1px #323a41;
}
.crown__house--background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -100;
  filter: blur(10px);
}
.crown__img {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.crown__img img {
  height: 100vh;
}
.crown__house--block {
  position: fixed;
  width: 100%;
  bottom: 2em;
}
.crown__house--side {
  display: flex;
  justify-content: center;
  bottom: 2em;
  margin: 0 auto;
  width: 100%;
}
.crown__house--side .crown__house--side-home {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  bottom: 2em;
  width: 60%;
  justify-content: space-between;
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  box-sizing: border-box;
  height: 34px;
  align-items: center;
  justify-content: space-between;
}
.crown__house--side-homeside button {
  margin: 0;
  margin: 0em 0.5em 0em 0.5em;
  text-shadow: 0px 0px;
}
.crown__houses--side-homeside button:focus {
  outline: none;
  border-bottom: 1px solid #323a41;
}
.crown__house--side-homename {
  text-transform: uppercase;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}
.outline-none {
  outline: none;
}

#bg {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#bg img {
  height: 100vh;
}
.container__form {
  position: fixed;
  overflow-y: auto;
  bottom: 4em;
  width: 100%;
}
.outerContainerLogin {
  margin: 0 auto;
}
.formContainer {
  width: 1178px;
  position: relative;
  height: auto;
  display: block;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 3em;
  padding-bottom: 3em;
  overflow-y: auto;
  /*
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  */
}
/*
.formContainer .formHeadline {
  margin-top: 2em;
  margin-bottom: 2em;
}
*/
form#loginForm {
  /* padding-left: 5em; */
  max-width: 40%;
  margin: 0 auto;
}
.formHeadline {
  max-width: 40%;
  margin: 0 auto;
}
p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  padding-bottom: 0.2em;
  color: #323a41;
}
.formContainer .formHeadline p {
  margin: 0;
  padding: 0;
  font-size: 1em;
  padding-bottom: 1.5em;
  color: #323a41;
}
.formContainer .formHeadline p.headline {
  font-size: 1.2em;
  padding-bottom: 1.5em;
  color: #323a41;
}
.form .formInput {
  width: 100%;
  height: auto;
  padding: 0.9em 0;
  margin: 0;
  display: flex;
  position: relative;
  white-space: pre-line;
}
.form .formInput input,
.form .formInput textarea {
  display: block;
  width: 100%;
  height: 2.4em;
  line-height: 2.4em;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.25em;
  border: 1px solid rgb(133, 133, 133);
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0;
  outline: none;
  position: relative;
  color: #323a41;
}
.form .formInput input.error {
  border-color: #ffc4c4;
}
.form .formInput textarea {
  height: 13em;
}
.form .formInput input:focus,
.form .formInput textarea:focus {
  color: #323a41;
}
.formInput.simpleButton input {
  background: rgba(255, 255, 255, 0);
}
.form .formInput.formButton input {
  border: 0;
  background-color: #323a41;
  color: #323a41;
  cursor: pointer;
}
.form .formInput.simpleButton {
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  line-height: normal;
  display: inline-block;
  white-space: normal;
}
.form .formInput.simpleButton input {
  line-height: normal;
  width: auto;
  height: auto;
  display: inline;
  border: 0;
  color: #323a41;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}
.error::placeholder {
  color: #ffc4c4;
}
::placeholder {
  color: #323a41;
}
.form input[type="button"] {
  margin-top: 0.9em;
}
.form .formInput.disabledInput {
  opacity: 0.6;
}
.form .formInput.disabledInput .loginPasswordView {
  z-index: -1;
}
.form .formInput .loginPasswordView {
  font-size: 0.8em;
  width: 3em;
  text-align: center;
  height: 2.4em;
  line-height: 2.4em;
  position: absolute;
  top: 1.25em;
  right: 0;
  cursor: pointer;
}
.form .formInput .loginPasswordView:before {
  content: "\e9ce";
}
.form .formInput .loginPasswordView.showPassword:before {
  content: "\e9d1";
}
.form .formInput button {
  width: 100%;
  padding: 1em;
  text-transform: uppercase;
}
.formNotice {
  display: flex;
}
.formNotice label {
  padding-left: 0.5em;
}
.link-daten {
  color: #323a41;
  text-decoration: underline;
  font-size: 15px;
  letter-spacing: 0 !important;
}
.formNotice p {
  font-size: 15px;
}
.formNotice input {
  margin-top: 5px;
}

.crown__house--block {
  position: fixed;
  width: 100%;
}
.crown__house--block-bottom {
  position: fixed;
  width: 1178px;
  bottom: 2em;
  z-index: 2;
  background: #fff;
  height: 35px;
}
.crown__house--side .crown__house--side-home {
  display: flex;
  flex-wrap: wrap;
  width: 1178px;
  justify-content: space-between;
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  box-sizing: border-box;
  height: 35px;
  align-items: center;
  justify-content: space-between;
}

.crown__house--side-homename {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 2em;
}
.crown__house--side-homepage {
  padding-right: 2em;
}
.crown__house--side-homepage a {
  color: #323a41;
  font-weight: 300;
}
.formFlats {
  display: flex;
  justify-content: center;
}

button.flats__button {
  padding: 1em;
  text-transform: uppercase;
  padding-left: 2em;
  padding-right: 2em;
  margin: 1em;
}

.form__headline {
  text-align: center;
}
</style>
