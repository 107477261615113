<template>
  <div id="app">
    <div class="local">
      <!--{{ this.$store.getters.user.loggedIn }}-->
    </div>
    <!--<Test class="login" />-->
    <!--<transition name="fade" mode="out-in">-->
      <router-view />
    <!--</transition>-->
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from "vuex";

//import Test from './../src/components/Test'
export default {
  created () {
    document.title = "FiftyNine Strandkai";
  },
  data: function () {
    return {
      crownKey: ''
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  components: {
    //Test
  },
  mounted() {
    var crown = localStorage.getItem('crown');
    if (crown) {
      //
    }
    else
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getAnonUser')
        .then((result) => {
          this.crownKey = result.data
        })
        .finally(() => {
          localStorage.setItem('crown', this.crownKey);
          crown = localStorage.getItem('crown');
          //this.$store.dispatch("ADD_LOCALSTORAGE_KEY", crown);
        });


  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

@font-face {
  font-family: "35-FTR";
  src: url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Black Oblique.otf")
      format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Black.otf") format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Bold Oblique.otf") format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Bold.otf") format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Demi Bold Oblique.otf")
      format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Demi Bold.otf") format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Extra Bold Oblique.otf")
      format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Extra Bold.otf") format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Light Oblique.otf") format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Light.otf") format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Medium Oblique.otf") format("otf"),
    url("~@/assets/fonts/ILOTT-TYPE - 35-FTR Medium.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Magnat";
  src: url("~@/assets/fonts/Rene Bieder - Magnat Head SemiBold.otf")
      format("otf"),
    url("~@/assets/fonts/Rene Bieder - Magnat Poster Light.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

/* Font Magnat */

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextMedium.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text Medium"),
    local("Magnat-TextMedium"),
    url("~@/assets/fonts/magnat/Magnat-TextMedium.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextMedium.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextMedium.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextLightItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text Light Italic"),
    local("Magnat-TextLightItalic"),
    url("~@/assets/fonts/magnat/Magnat-TextLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextLightItalic.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextLightItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextLightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextRegularItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text Regular Italic"),
    local("Magnat-TextRegularItalic"),
    url("~@/assets/fonts/magnat/Magnat-TextRegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextRegularItalic.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextRegularItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextRegularItalic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadExtraBold.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head ExtraBold"),
    local("Magnat-HeadExtraBold"),
    url("~@/assets/fonts/magnat/Magnat-HeadExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadExtraBold.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadExtraBold.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadLight.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head Light"),
    local("Magnat-HeadLight"),
    url("~@/assets/fonts/magnat/Magnat-HeadLight.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadLight.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadLight.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextBoldItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text Bold Italic"),
    local("Magnat-TextBoldItalic"),
    url("~@/assets/fonts/magnat/Magnat-TextBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextBoldItalic.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextBoldItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextLight.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text Light"),
    local("Magnat-TextLight"),
    url("~@/assets/fonts/magnat/Magnat-TextLight.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextLight.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextLight.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextExtraBoldItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text ExtraBold Italic"),
    local("Magnat-TextExtraBoldItalic"),
    url("~@/assets/fonts/magnat/Magnat-TextExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextExtraBoldItalic.woff2")
      format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextExtraBoldItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextExtraBoldItalic.ttf")
      format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadSemiBoldItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head SemiBold Italic"),
    local("Magnat-HeadSemiBoldItalic"),
    url("~@/assets/fonts/magnat/Magnat-HeadSemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadSemiBoldItalic.woff2")
      format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadSemiBoldItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadSemiBoldItalic.ttf")
      format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadRegular.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head Regular"),
    local("Magnat-HeadRegular"),
    url("~@/assets/fonts/magnat/Magnat-HeadRegular.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadRegular.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadRegular.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadLightItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head Light Italic"),
    local("Magnat-HeadLightItalic"),
    url("~@/assets/fonts/magnat/Magnat-HeadLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadLightItalic.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadLightItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadLightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadMedium.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head Medium"),
    local("Magnat-HeadMedium"),
    url("~@/assets/fonts/magnat/Magnat-HeadMedium.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadMedium.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadMedium.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadExtraBoldItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head ExtraBold Italic"),
    local("Magnat-HeadExtraBoldItalic"),
    url("~@/assets/fonts/magnat/Magnat-HeadExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadExtraBoldItalic.woff2")
      format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadExtraBoldItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadExtraBoldItalic.ttf")
      format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextSemiBoldItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text SemiBold Italic"),
    local("Magnat-TextSemiBoldItalic"),
    url("~@/assets/fonts/magnat/Magnat-TextSemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextSemiBoldItalic.woff2")
      format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextSemiBoldItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextSemiBoldItalic.ttf")
      format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextRegular.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text Regular"),
    local("Magnat-TextRegular"),
    url("~@/assets/fonts/magnat/Magnat-TextRegular.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextRegular.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextRegular.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextSemiBold.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text SemiBold"),
    local("Magnat-TextSemiBold"),
    url("~@/assets/fonts/magnat/Magnat-TextSemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextSemiBold.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextSemiBold.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextSemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextBold.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text Bold"),
    local("Magnat-TextBold"),
    url("~@/assets/fonts/magnat/Magnat-TextBold.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextBold.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextBold.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextMediumItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text Medium Italic"),
    local("Magnat-TextMediumItalic"),
    url("~@/assets/fonts/magnat/Magnat-TextMediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextMediumItalic.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextMediumItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextMediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Text";
  src: url("~@/assets/fonts/magnat/Magnat-TextExtraBold.eot");
  src: local("~@/assets/fonts/magnat/Magnat Text ExtraBold"),
    local("Magnat-TextExtraBold"),
    url("~@/assets/fonts/magnat/Magnat-TextExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-TextExtraBold.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-TextExtraBold.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-TextExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadRegularItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head Regular Italic"),
    local("Magnat-HeadRegularItalic"),
    url("~@/assets/fonts/magnat/Magnat-HeadRegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadRegularItalic.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadRegularItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadRegularItalic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadSemiBold.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head SemiBold"),
    local("Magnat-HeadSemiBold"),
    url("~@/assets/fonts/magnat/Magnat-HeadSemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadSemiBold.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadSemiBold.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadSemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Magnat Head";
  src: url("~@/assets/fonts/magnat/Magnat-HeadMediumItalic.eot");
  src: local("~@/assets/fonts/magnat/Magnat Head Medium Italic"),
    local("Magnat-HeadMediumItalic"),
    url("~@/assets/fonts/magnat/Magnat-HeadMediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/magnat/Magnat-HeadMediumItalic.woff2") format("woff2"),
    url("~@/assets/fonts/magnat/Magnat-HeadMediumItalic.woff") format("woff"),
    url("~@/assets/fonts/magnat/Magnat-HeadMediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* ----- */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "35-FTR", sans-serif;
  font-weight: 300;
}
p,
body {
  font-family: "35-FTR", sans-serif;
  color: #323a41;
  font-size: 15px;
  /*letter-spacing: 0.06rem;*/
}
a {
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  text-decoration: none;
  color: #fff;
  letter-spacing: 0.1rem !important;
}
button {
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.1rem !important;
  color: #323a41;
}
a:hover {
  color: #ad8059;
}
.router-link-active {
  color: #ad8059;
  text-shadow: 0px 0px 0px #323a41;
}
body {
  margin: 0;
}
.login {
  position: fixed;
  right: 2em;
}
.local {
  position: fixed;
  font-size: 6pt;
  color: white;
  padding: 1em;
  bottom: 0;
}

.pointer {
  cursor: pointer;
}

/*loader*/
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  top: 0;
  background: #ccc;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
/*---- end loader */
button:focus,
button:active {
  outline: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>
