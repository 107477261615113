import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        color: 'color:silver',
        colorWohnen: 'color:silver',
        cost: 0,
        framedMeters: 0,
        selectConfigurator: {
            bad: [{
                    subLineItemsName: "Bauseitige Möbel",
                    productID: "77958"
                },
                {
                    subLineItemsName: "Waschtischarmatur",
                    productID: "75002"
                },
                {
                    subLineItemsName: "Waschtisch mit Becken",
                    productID: "77902"
                }
            ],
            wohnen: [{
                    subLineItemsName: "Wandfarbe",
                    productID: "77875"
                },
                {
                    subLineItemsName: "Parkett",
                    productID: "77872"
                },
                {
                    subLineItemsName: "Türbeschlag",
                    productID: "77882"
                }
            ]
        },
        zQuery: [],
        configurator: {
            bad: {
                src: 'https://firebasestorage.googleapis.com/v0/b/cadman-63dc7.appspot.com/o/Images%2F1a48aacf-870e-4357-bb09-e31eaf8001f1.jpg?alt=media&token=f9655f2b-8743-47c7-9d45-5b7ef01ccd8d',
                select: []
            },
            wohnen: {
                src: 'https://firebasestorage.googleapis.com/v0/b/cadman-63dc7.appspot.com/o/Images%2F8f4dbab0-8a1b-49c9-880a-a46c0dd3ab4f.jpg?alt=media&token=295aa2bc-2c32-4ed2-bf78-f24819a47ca8',
                select: []
            }
        },
        localstorage: {
            key: '',
            flat: null
        },
        save: {
            room: null,
            active: null,
            style: null
        },
        user: {
            loggedIn: false,
            data: null
        },
        products: [],
        count: 0,
        moodboard: {
            flat: null,
            wall: null,
            floor: null
        }
    },
    getters: {
        color(state) {
            return state.color
        },
        colorWohnen(state) {
            return state.colorWohnen
        },
        cost(state) {
            return state.cost
        },
        framedMeters(state) {
            return state.framedMeters
        },
        selectConfigurator(state) {
            var array1 = state.selectConfigurator.bad
            const array2 = state.selectConfigurator.wohnen
            const arr = array1.concat(array2);
            return arr
        },
        zQuery(state) {
            return state.zQuery
        },
        configurator(state) {
            return state.configurator
        },
        user(state) {
            return state.user
        },
        products(state) {
            return state.products
        },
        count(state) {
            return state.count
        },
        localstorage(state) {
            return state.localstorage
        },
        save(state) {
            return state.save
        },
        moodboard(state) {
            return state.moodboard
        }
    },
    mutations: {
        SET_COLOR(state, value) {
            state.color = value
        },
        SET_COLORWOHNEN(state, value) {
            state.colorWohnen = value
        },
        SET_COST(state, value) {
            state.cost = value
        },
        SET_FRAMEDMETERS(state, value) {
            state.framedMeters = value
        },
        SET_SELECT(state, {
            value,
            room
        }) {
            if (room == 'Bad') {
                state.selectConfigurator.bad = value
            }
            if (room == 'Wohnen') {
                state.selectConfigurator.wohnen = value
            }
        },
        SET_ZQUERY(state, value) {
            state.zQuery = value;
        },
        SET_CONFIGURATOR(state, {
            src,
            select,
            room
        }) {
            if (room == 'Bad') {
                state.configurator.bad.src = src;
                state.configurator.bad.select = select
            }
            if (room == 'Wohnen') {
                state.configurator.wohnen.src = src;
                state.configurator.wohnen.select = select
            }
        },
        SET_SAVE(state, value) {
            state.save = value;
        },
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },

        SET_LOCALSTORAGE(state, value) {
            state.localstorage.key = value
        },
        SET_FLAT(state, value) {
            state.localstorage.flat = value
        },

        SET_MOODBOARD(state, {
            picked,
            value
        }) {
            if (picked == 'Wall') {
                state.moodboard.wall = value
            }
            if (picked == 'Floor') {
                state.moodboard.floor = value
            }
            if (picked == 'flatID') {
                state.moodboard.flat = value
            }
        },

        changeProduct(state, data) {
            state.count++
            state.products = state.products.push({
                id: state.count,
                name: data
            });
        },

        EMPTY(state) {
            state.products = []
        },

        ADD_TODO(state, data) {
            state.count++
            state.products.push({
                produdctID: data.produdctID,
                id: data.id,
                name: data.product,
                actions: data.action
            });
        },

        CHANGE_PIKED_ACTIVE(state, product_active) {
            for (let key in state.products) {
                if (state.products[key].produdctID == product_active.productActiveGroup && state.products[key].id == product_active.productActiveID) {
                    state.products[key].actions = true
                    //state.products.splice(key, 1);
                }

                if (state.products[key].produdctID == product_active.productActiveGroup && state.products[key].id != product_active.productActiveID) {
                    state.products[key].actions = false
                    //state.products.splice(key, 1);
                }
                //alert('delete' + delete_product.id)
            }
        }
    },
    actions: {
        ADD_COLOR({
            commit
        }, value) {
            commit("SET_COLOR", value);
        },

        ADD_COLORWOHNEN({
            commit
        }, value) {
            commit("SET_COLORWOHNEN", value);
        },

        ADD_COST({
            commit
        }, value) {
            commit("SET_COST", value);
        },

        ADD_FRAMEDMETERS({
            commit
        }, value) {
            commit("SET_FRAMEDMETERS", value);
        },

        fetchUser({
            commit
        }, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email,
                    key: localStorage.getItem('crown')
                });
            } else {
                commit("SET_USER", null);
            }
        },

        EMPTY_PRODUCT({
            commit
        }) {
            commit("EMPTY")
        },

        ADD_PRODUCT({
            commit
        }, product) {
            commit("ADD_TODO", product);
        },

        ADD_SAVE({
            commit
        }, value) {
            commit("SET_SAVE", value)
        },

        CHANGE_PICKED({
            commit
        }, product_active) {
            commit("CHANGE_PIKED_ACTIVE", product_active)
        },

        ADD_LOCALSTORAGE_KEY({
            commit
        }, value) {
            commit("SET_LOCALSTORAGE", value)
        },

        ADD_LOCALSTORAGE_FLAT({
            commit
        }, value) {
            commit("SET_FLAT", value)
        },

        ADD_MOODBOARD({
            commit
        }, {
            picked,
            value
        }) {
            commit("SET_MOODBOARD", {
                picked,
                value
            })
        },

        ADD_CONFIGURATOR_SRC({
            commit
        }, {
            src,
            select,
            room
        }) {
            commit("SET_CONFIGURATOR", {
                src,
                select,
                room
            })
        },

        ADD_SELECT({
            commit
        }, {
            value,
            room
        }) {
            commit("SET_SELECT", {
                value,
                room
            })
        },

        ADD_ZQUERY({
            commit
        }, value) {
            commit("SET_ZQUERY", value)
        },
    }
});