import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './../src/store';
import firebase from 'firebase/app';
import VueSocialSharing from 'vue-social-sharing'

//fonts

import Ftr1 from "./assets/fonts/ILOTT-TYPE - 35-FTR Black Oblique.otf"
import Ftr2 from "./assets/fonts/ILOTT-TYPE - 35-FTR Black.otf"
import Ftr3 from "./assets/fonts/ILOTT-TYPE - 35-FTR Bold Oblique.otf"
import Ftr4 from "./assets/fonts/ILOTT-TYPE - 35-FTR Bold.otf"
import Ftr5 from "./assets/fonts/ILOTT-TYPE - 35-FTR Demi Bold Oblique.otf"
import Ftr6 from "./assets/fonts/ILOTT-TYPE - 35-FTR Demi Bold.otf"
import Ftr7 from "./assets/fonts/ILOTT-TYPE - 35-FTR Extra Bold Oblique.otf"
import Ftr8 from "./assets/fonts/ILOTT-TYPE - 35-FTR Extra Bold.otf"
import Ftr9 from "./assets/fonts/ILOTT-TYPE - 35-FTR Light Oblique.otf"
import Ftr10 from "./assets/fonts/ILOTT-TYPE - 35-FTR Light.otf"
import Ftr11 from "./assets/fonts/ILOTT-TYPE - 35-FTR Medium Oblique.otf"
import Ftr12 from "./assets/fonts/ILOTT-TYPE - 35-FTR Medium.otf"
import Magnat1 from "./assets/fonts/Rene Bieder - Magnat Head SemiBold.otf"
import Magnat2 from "./assets/fonts/Rene Bieder - Magnat Poster Light.otf"
import VueRouter from "vue-router";
Vue.use(VueRouter);

console.log(Ftr1, Ftr2, Ftr3, Ftr4, Ftr5, Ftr6, Ftr7, Ftr8, Ftr9, Ftr10, Ftr11, Ftr12, Magnat1, Magnat2);

Vue.config.ignoredElements = [
  'a-scene',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-sphere',
  'a-cylinder',
  'a-plane'
];

Vue.config.productionTip = false;

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(require('vue-moment'));
Vue.use(VueSocialSharing)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    console.log(err)
  });
};

const configOptions = {
  apiKey: "AIzaSyB72DU5zZvzz2ytBvw8-ss2HdH5cAIGsTA",
  authDomain: "cadman-63dc7.firebaseapp.com",
  databaseURL: "https://cadman-63dc7.firebaseio.com",
  projectId: "cadman-63dc7",
  storageBucket: "cadman-63dc7.appspot.com",
  messagingSenderId: "400167877731",
  appId: "1:400167877731:web:082c0d00463f7d61714c56"
};

firebase.initializeApp(configOptions);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')